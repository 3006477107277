<template>
  <div class="text-center">
    <v-snackbar
      v-model="show"
      :timeout="timeout"
      :color="color"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    name: 'DashboardCoreAlertSnackbar',
    data: () => ({
      show: false,
      text: '',
      color: '',
      timeout: 4000,
      colors: {
        success: 'green',
        warn: 'red',
      },
    }),

    created() {
      /*
      this.$store.watch(state => state.snack, () => {
        const msg = this.$store.state.snack;
        if (msg !== '') {
          this.show = true;
          this.text = this.$store.state.snack;
          this.color = this.colors[this.$store.state.type];
          this.$store.commit('SET_SNACK', {
            text: '',
            type: '',
          });
        }
      })
      */
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'SET_SNACK') {
          this.text = state.snackbar.text;
          this.color = this.colors[state.snackbar.type];
          this.show = true;
        }
      })
    },
  }
</script>
